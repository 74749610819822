import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const DeviceSettings = () => import('@/components/deviceSettings/deviceSettings');

const RegisterPages = () => import('@/components/register/index');
const Register = () => import('@/components/register/register');
const RegAccount = () => import('@/components/register/regAccount');
const RegProfile = () => import('@/components/register/regProfile');
const RegBeauty = () => import('@/components/register/regBeauty');
const RegFinish = () => import('@/components/register/regFinish');
const smartMirror = () => import('@/components/smartMirror/smartMirror');
const ranking = () => import('@/components/ranking-new/ranking');

const maskstationIndex = () => import('@/components/socomask/mask-station-index');
const maskstation = () => import('@/components/socomask/maskstation/maskstation');
// const maskstationQRCode = () => import('@/components/socomask/qrcode/qrcode');
const maskstationBeautyProfile = () => import('@/components/socomask/beauty-profile/beauty-profile');
const maskstationProductDetail = () => import('@/components/socomask/product-detail/product-detail');
const maskstationPlayQuiz = () => import('@/components/socomask/play-quiz/play-quiz');
const maskstationLoginSuccess = () => import('@/components/socomask/login-success/login-success');
const maskstationLoginFailed = () => import('@/components/socomask/login-failed/login-failed');

const recommendationMachine = () => import('@/components/recommendationMachine/recommendationMachine');
const home = () => import('@/components/recommendationMachine/home/home');
const splash = () => import('@/components/recommendationMachine/splash/splash');
const scanQR = () => import('@/components/recommendationMachine/scanQR/scanQR');
const userQR = () => import('@/components/recommendationMachine/userScanQR/userQR');
const userScanError = () => import('@/components/recommendationMachine/userScanError/userScanError');
const emptyRecommendation = () => import('@/components/recommendationMachine/emptyRecommendation/emptyRecommendation');
const productRecommendation = () =>
  import('@/components/recommendationMachine/productRecommendation/productRecommendation');
const productCategory = () => import('@/components/recommendationMachine/productCategory/productCategory');
const productSubCategory = () => import('@/components/recommendationMachine/productSubCategory/productSubCategory');
const productCatelog = () => import('@/components/recommendationMachine/productCatelog/productCatelog');
const searchProduct = () => import('@/components/recommendationMachine/searchProduct/searchProduct');
const productDetails = () => import('@/components/recommendationMachine/productDetails/productDetails');
const promotion = () => import('@/components/recommendationMachine/promotion/promotion');
const productDetailsNoReview = () =>
  import('@/components/recommendationMachine/productDetailsNoReview/productDetailsNoReview');

const checkinStationIndex = () => import('@/components/checkinStation/checkin-station-index');
const checkinStationHome = () => import('@/components/checkinStation/home/home');
const checkin = () => import('@/components/checkin/checkin');

const lipstickbarIndex = () => import('@/components/lipstickbar/lipstickbar-index');
const lipstickbarHome = () => import('@/components/lipstickbar/home/home');
const lipstickbarBrands = () => import('@/components/lipstickbar/lipstick-brands/lipstick-brands');
const lipstickbarProductCatalogue = () => import('@/components/lipstickbar/product-catalogue/product-catalogue');
const lipstickbarProductDetail = () => import('@/components/lipstickbar/product-detail/product-detail');

const kokasScanQR = () => import('@/components/kokas/kokasScanQR/kokasScanQR');
const kokasUserQR = () => import('@/components/kokas/kokasUserScanQR/kokasUserQR');
const kokasUserScanError = () => import('@/components/kokas/userScanError/userScanError');

const selfCheckoutScanQR = () => import('@/components/selfCheckout/selfCheckoutScanQR/selfCheckoutScanQR');
const selfCheckoutUserScanQR = () => import('@/components/selfCheckout/selfCheckoutUserScanQR/selfCheckoutUserQR');
const selfCheckoutUserScanError = () => import('@/components/selfCheckout/userScanError/userScanError');

const vendingMachineHomeVn = () => import('@/components/vendingMachine/home/home');
const vendingMachineHome = () => import('@/components/vendingMachine/scanQR/scanQR'); // SOCO Beauty Star home @POS-3101
const vendingMachineScan = () => import('@/components/vendingMachine/scanQR/scanQR');
const vendingMachineScanVn = () => import('@/components/vendingMachine/scanQR/scanQR-vn');
const withdrawGift = () => import('@/components/vendingMachine/withdrawGift/withdrawGift');
const verificationError = () => import('@/components/vendingMachine/verificationError/verificationError');
const vendingMachineProductList = () => import('@/components/vendingMachine/product-list/productList');
const vendingMachineProduct = () => import('@/components/vendingMachine/product/product');

// Pimple Pop
// const vendingMachinePimplePop = () => import('@/components/vendingMachine/pimple-pop/landing')
// const vendingMachinePimplePopHowToPlay = () => import('@/components/vendingMachine/pimple-pop/how-to-play')
// const vendingMachineGame = () => import('@/components/vendingMachine/pimple-pop/game')
// const vendingMachineGameFinish = () => import('@/components/vendingMachine/pimple-pop/finish')

// Big Bang Boom
const vendingMachinePimplePopVn = () => import('@/components/vendingMachine/big-bang-boom/landing');
const vendingMachinePimplePopHowToPlayVn = () => import('@/components/vendingMachine/big-bang-boom/how-to-play');
const vendingMachineGameVn = () => import('@/components/vendingMachine/big-bang-boom/game');
const vendingMachineGameFinishVn = () => import('@/components/vendingMachine/big-bang-boom/finish');

// SOCO Beauty Star
const vendingMachinePimplePop = () => import('@/components/vendingMachine/soco-beauty-star/landing');
const vendingMachinePimplePopHowToPlay = () => import('@/components/vendingMachine/soco-beauty-star/how-to-play');
const vendingMachineGame = () => import('@/components/vendingMachine/soco-beauty-star/game');
const vendingMachineGameFinish = () => import('@/components/vendingMachine/soco-beauty-star/finish');

// SOCO Event Big Bang Boom ID
const socoVendingMachineHome = () => import('@/components/socoVendingMachineEvent/home/home.vue');
const qrNotFound = () => import('@/components/socoVendingMachineEvent/home/qr-not-found.vue');
const bigBangEventMachineScan = () => import('@/components/socoVendingMachineEvent/scanQR/scanQR');
const vmBigBangLanding = () => import('@/components/socoVendingMachineEvent/big-bang-boom/landing');
const vmBigBangHowToPlay = () => import('@/components/socoVendingMachineEvent/big-bang-boom/how-to-play');
const vmBigBangGame = () => import('@/components/socoVendingMachineEvent/big-bang-boom/game');
const vmBigBangGameFinish = () => import('@/components/socoVendingMachineEvent/big-bang-boom/finish');

const vendingMachineSuccess = () => import('@/components/vendingMachine/success/success');
const gondolaMini = () => import('@/components/gondolaMini/index');
const gondolaMiniHome = () => import('@/components/gondolaMini/home/home');
const gondolaMiniSignedIn = () => import('@/components/gondolaMini/home/signed-in/signed-in');
const gondolaMiniUserNotFound = () => import('@/components/gondolaMini/home/user-not-found/user-not-found');
const gondolaMiniRecommendation = () => import('@/components/gondolaMini/recommendation/recommendation');
const gondolaMiniNoRecommendation = () =>
  import('@/components/gondolaMini/recommendation/no-recommendation/no-recommendation');
const gondolaMiniDetail = () => import('@/components/gondolaMini/product-detail/product-detail');
const gondolaMiniLogout = () => import('@/components/gondolaMini/logout/logout');

const vmCola = () => import('@/components/vm-cola/index');
const vmColaHome = () => import('@/components/vm-cola/home/home');
const vmColaProducts = () => import('@/components/vm-cola/products/products');
const vmColaOrder = () => import('@/components/vm-cola/order/order');

const vmColaHomeV2 = () => import('@/components/vm-cola/homev2/homev2');
const vmColaSocoPoints = () => import('@/components/vm-cola/order/soco-points/soco-points');
const vmColaOffline = () => import('@/components/vm-cola/offline/offline');

const dandelionStore = () => import('@/components/dandelion-store/dandelion-store');
const dandelionStoreIndex = () => import('@/components/dandelion-store/index');
const dandelionStoreProductDetail = () => import('@/components/dandelion-store/dandelion-product-detail');

const checkinEventScanQR = () => import('@/components/checkinStationEvent/eventScanQR/kokasScanQR');

const EmptyParentComponent = {
  template: '<router-view></router-view>',
};

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'DeviceSettings',
      component: DeviceSettings,
      meta: {
        title: 'Sociolla Offline Store Device Settings',
      },
    },
    {
      path: '/register',
      component: RegisterPages,
      children: [
        {
          path: '',
          name: 'Register',
          component: Register,
        },
        {
          path: 'account',
          name: 'RegAccount',
          component: RegAccount,
        },
        {
          path: 'profile',
          name: 'RegProfile',
          component: RegProfile,
        },
        {
          path: 'beauty',
          name: 'RegBeauty',
          component: RegBeauty,
        },
        {
          path: 'finish',
          name: 'RegFinish',
          component: RegFinish,
        },
      ],
    },
    {
      path: '/smart-mirror',
      name: 'smartMirror',
      component: smartMirror,
      meta: {
        autoRefresh: true,
        refreshFrequency: 1, //in minutes
      },
    },
    {
      path: '/ranking',
      name: 'ranking',
      component: ranking,
      meta: {
        // autoRefresh: true,
        // refreshFrequency: 200 //in minutes
      },
    },
    {
      path: '/mask-station',
      name: 'maskstationIndex',
      component: maskstationIndex,
      children: [
        {
          path: 'home',
          name: 'maskstationHome',
          component: maskstation,
        },
        {
          path: 'login-success',
          name: 'maskstationLoginSuccess',
          component: maskstationLoginSuccess,
        },
        {
          path: 'login-failed',
          name: 'maskstationLoginFailed',
          component: maskstationLoginFailed,
        },
        {
          path: 'beauty-profile',
          name: 'maskstationBeautyProfile',
          component: maskstationBeautyProfile,
        },
        {
          path: 'product-detail/:product_id?',
          name: 'maskstationProductDetail',
          component: maskstationProductDetail,
        },
        {
          path: 'play-quiz',
          name: 'maskstationPlayQuiz',
          component: maskstationPlayQuiz,
        },
      ],
    },
    {
      path: '/recommendation',
      component: recommendationMachine,
      name: 'recommendation',
      children: [
        {
          path: 'splash',
          name: 'splash',
          component: splash,
        },
        {
          path: 'scan-qr',
          name: 'scanQR',
          component: scanQR,
        },
        {
          path: 'user-qr',
          name: 'userQR',
          component: userQR,
        },
        {
          path: 'user-scan-error',
          name: 'userScanError',
          component: userScanError,
        },
        {
          path: 'empty-recommendation',
          name: 'emptyRecommendation',
          component: emptyRecommendation,
        },
        {
          path: 'product',
          name: 'productRecommendation',
          component: productRecommendation,
        },
        {
          path: 'product-category/:id',
          name: 'productCategory',
          component: productCategory,
        },
        {
          path: 'product-sub-category/:id',
          name: 'productSubCategory',
          component: productSubCategory,
        },
        {
          path: 'product-catelog/:id',
          name: 'productCatelog',
          component: productCatelog,
        },
        {
          path: 'product-details',
          name: 'productDetails',
          component: productDetails,
        },
        {
          path: 'search-product',
          name: 'searchProduct',
          component: searchProduct,
        },
        {
          path: 'home',
          name: 'home',
          component: home,
        },
        {
          path: 'promotion',
          name: 'promotion',
          component: promotion,
        },
        {
          path: 'product-details-review-content-missing',
          name: 'productDetailsNoReview',
          component: productDetailsNoReview,
        },
      ],
    },
    {
      path: '/checkin-station-old',
      name: 'checkinStationIndex',
      component: checkinStationIndex,
      children: [
        {
          path: 'home',
          name: 'checkinStationHome',
          component: checkinStationHome,
        },
      ],
    },
    {
      path: '/lipstickbar',
      name: 'lipstickbarIndex',
      component: lipstickbarIndex,
      children: [
        {
          path: 'home',
          name: 'lipstickbarHome',
          component: lipstickbarHome,
        },
        {
          path: 'brands',
          name: 'lipstickbarBrands',
          component: lipstickbarBrands,
        },
        {
          path: 'product-catalogue/:brand_id',
          name: 'lipstickbarProductCatalogue',
          component: lipstickbarProductCatalogue,
        },
        {
          path: 'product-detail/:product_id',
          name: 'lipstickbarProductDetail',
          component: lipstickbarProductDetail,
        },
      ],
    },
    // {
    //   path: '/kokas',
    //   name: 'kokasIndex',
    //   component: kokasIndex,
    //   children: [
    //     {
    //     path: 'home',
    //     name: 'kokasHome',
    //     component: kokasHome
    //   },
    //   {
    //     path: 'user-scan-error',
    //     name: 'kokasUserScanError',
    //     component: kokasUserScanError
    //   }
    // ]
    // },
    {
      path: '/checkin-station-event/event/:id',
      component: checkinEventScanQR,
      name: 'checkinStationEvent',
    },
    {
      path: '/checkin-station-event/store/:id',
      component: checkinEventScanQR,
      name: 'checkinStationEvent',
    },
    {
      path: '/checkin-station',
      component: EmptyParentComponent,
      name: 'kokas',
      children: [
        {
          path: 'home',
          name: 'kokasScanQR',
          component: kokasScanQR,
        },
        {
          path: 'user-qr',
          name: 'kokasUserQR',
          component: kokasUserQR,
        },
        {
          path: 'user-scan-error',
          name: 'kokasUserScanError',
          component: kokasUserScanError,
        },
      ],
    },
    {
      path: '/self-checkout',
      component: EmptyParentComponent,
      name: 'selfCheckout',
      children: [
        {
          path: 'home',
          name: 'selfCheckoutScanQR',
          component: selfCheckoutScanQR,
        },
        {
          path: 'user-qr',
          name: 'selfCheckoutUserScanQR',
          component: selfCheckoutUserScanQR,
        },
        {
          path: 'user-scan-error',
          name: 'selfCheckoutUserScanError',
          component: selfCheckoutUserScanError,
        },
      ],
    },
    {
      path: '/vending-machine',
      component: EmptyParentComponent,
      name: 'vendingmachine',
      children: [
        {
          path: 'home',
          name: 'vendingMachineHome',
          component: vendingMachineHome,
        },
        {
          path: 'scan-qr',
          name: 'vendingMachineScan',
          component: vendingMachineScan,
        },
        {
          path: 'withdraw-gift',
          name: 'withdrawGift',
          component: withdrawGift,
        },
        {
          path: 'verification-error',
          name: 'verificationError',
          component: verificationError,
        },
        {
          path: 'product-list',
          name: 'vendingMachineProductList',
          component: vendingMachineProductList,
        },
        {
          path: 'product',
          name: 'vendingMachineProduct',
          component: vendingMachineProduct,
        },
        {
          path: 'pimple-pop',
          name: 'vendingMachinePimplePop',
          component: vendingMachinePimplePop,
        },
        {
          path: 'pimple-pop/how-to-play',
          name: 'vendingMachinePimplePopHowToPlay',
          component: vendingMachinePimplePopHowToPlay,
        },
        {
          path: 'pimple-pop/start',
          name: 'vendingMachinePimplePopGame',
          component: vendingMachineGame,
        },
        {
          path: 'pimple-pop/finish',
          name: 'vendingMachinePimplePopGameFinish',
          component: vendingMachineGameFinish,
        },
        {
          path: 'success',
          name: 'vendingMachineSuccess',
          component: vendingMachineSuccess,
        },
      ],
    },
    {
      path: '/vending-machine',
      component: vmCola,
      children: [
        {
          path: 'login',
          name: 'vendingMachineLogin',
          component: vmColaOrder,
        },
      ],
    },
    {
      path: '/vending-machine-vn',
      alias: '/vending-machine-id',
      component: EmptyParentComponent,
      name: 'vendingmachine',
      children: [
        {
          path: 'home',
          name: 'vendingMachineHome',
          component: vendingMachineHomeVn,
        },
        {
          path: 'scan-qr',
          name: 'vendingMachineScan',
          component: vendingMachineScanVn,
        },
        {
          path: 'withdraw-gift',
          name: 'withdrawGift',
          component: withdrawGift,
        },
        {
          path: 'verification-error',
          name: 'verificationError',
          component: verificationError,
        },
        {
          path: 'product-list',
          name: 'vendingMachineProductList',
          component: vendingMachineProductList,
        },
        {
          path: 'product',
          name: 'vendingMachineProduct',
          component: vendingMachineProduct,
        },
        {
          path: 'pimple-pop',
          name: 'vendingMachinePimplePop',
          component: vendingMachinePimplePopVn,
        },
        {
          path: 'pimple-pop/how-to-play',
          name: 'vendingMachinePimplePopHowToPlay',
          component: vendingMachinePimplePopHowToPlayVn,
        },
        {
          path: 'pimple-pop/start',
          name: 'vendingMachinePimplePopGame',
          component: vendingMachineGameVn,
        },
        {
          path: 'pimple-pop/finish',
          name: 'vendingMachinePimplePopGameFinish',
          component: vendingMachineGameFinishVn,
        },
        {
          path: 'success',
          name: 'vendingMachineSuccess',
          component: vendingMachineSuccess,
        },
      ],
    },
    {
      path: '/gondola-mini',
      name: 'gondolaMini',
      component: gondolaMini,
      children: [
        {
          path: 'home',
          name: 'gondolaMiniHome',
          component: gondolaMiniHome,
        },
        {
          path: 'signed-in',
          name: 'gondolaMiniSignedIn',
          component: gondolaMiniSignedIn,
        },
        {
          path: 'user-not-found',
          name: 'gondolaMiniUserNotFound',
          component: gondolaMiniUserNotFound,
        },
        {
          path: 'recommendation',
          name: 'gondolaMiniRecommendation',
          component: gondolaMiniRecommendation,
        },
        {
          path: 'no-recommendation',
          name: 'gondolaMiniNoRecommendation',
          component: gondolaMiniNoRecommendation,
        },
        {
          path: 'product-detail/:product_id',
          name: 'gondolaMiniDetail',
          component: gondolaMiniDetail,
        },
        {
          path: 'logout',
          name: 'gondolaMiniLogout',
          component: gondolaMiniLogout,
        },
      ],
    },
    {
      path: '/vmcola',
      component: vmCola,
      children: [
        {
          path: '',
          name: 'vmColaHome',
          component: vmColaHome,
        },
        {
          path: 'home',
          name: 'vmColaHomeV2',
          component: vmColaHomeV2,
        },
        {
          path: 'products',
          name: 'vmColaProducts',
          component: vmColaProducts,
          meta: {
            autoRefresh: true,
            refreshFrequency: 200, //in minutes
          },
        },
        {
          path: 'order',
          name: 'vmColaOrder',
          component: vmColaOrder,
          meta: {
            autoRefresh: true,
            refreshFrequency: 200, //in minutes
          },
        },
        {
          path: 'soco-points',
          name: 'vmColaSocoPoints',
          component: vmColaSocoPoints,
          meta: {
            autoRefresh: true,
            refreshFrequency: 200, // in minutes
          },
        },
        {
          path: 'order/login',
          name: 'vmColaLogin',
          component: vmColaOrder,
          meta: {
            autoRefresh: true,
            refreshFrequency: 200, //in minutes
          },
        },
        {
          path: 'order/payment',
          name: 'vmColaPayment',
          component: vmColaOrder,
          meta: {
            autoRefresh: true,
            refreshFrequency: 200, //in minutes
          },
        },
        {
          path: 'order/payment-success',
          name: 'vmColaPaymentSuccess',
          component: vmColaOrder,
          meta: {
            autoRefresh: true,
            refreshFrequency: 200, //in minutes
          },
        },
        {
          path: 'order/payment-success-failed',
          name: 'vmColaPaymentSuccessFailed',
          component: vmColaOrder,
          meta: {
            autoRefresh: true,
            refreshFrequency: 200, //in minutes
          },
        },
        {
          path: 'order/payment-failed',
          name: 'vmColaPaymentFailed',
          component: vmColaOrder,
          meta: {
            autoRefresh: true,
            refreshFrequency: 200, //in minutes
          },
        },
        {
          path: 'offline',
          name: 'vmColaOffline',
          component: vmColaOffline,
          meta: {
            autoRefresh: true,
            refreshFrequency: 200, //in minutes
          },
        },
      ],
    },
    {
      path: '/dandelion-store/:store_alias',
      component: dandelionStore,
      props: true,
      children: [
        {
          path: '',
          name: 'dandelionIndex',
          component: dandelionStoreIndex,
        },
        {
          path: 'product/:id',
          name: 'dandelionProductDetail',
          component: dandelionStoreProductDetail,
          props: true,
        },
      ],
    },
    {
      path: '/checkin',
      name: 'CheckIn',
      component: checkin,
    },
    {
      path: '/soco-vending-machine-event',
      name: 'SocoVendingMachineEvent',
      component: EmptyParentComponent,
      children: [
        {
          path: 'home',
          name: 'vendingMachineHome',
          component: socoVendingMachineHome,
        },
        {
          path: 'qr-not-found',
          name: 'qrNotFound',
          component: qrNotFound,
        },
        {
          path: 'scan-qr',
          name: 'bigBangEventMachineScan',
          component: bigBangEventMachineScan,
        },
        {
          path: 'withdraw-gift',
          name: 'withdrawGift',
          component: withdrawGift,
        },
        {
          path: 'verification-error',
          name: 'verificationError',
          component: verificationError,
        },
        {
          path: 'product-list',
          name: 'vendingMachineProductList',
          component: vendingMachineProductList,
        },
        {
          path: 'product',
          name: 'vendingMachineProduct',
          component: vendingMachineProduct,
        },
        {
          path: 'bbb-landing',
          name: 'vmBigBangLanding',
          component: vmBigBangLanding,
        },
        {
          path: 'bbb-how-to-play',
          name: 'vmBigBangHowToPlay',
          component: vmBigBangHowToPlay,
        },
        {
          path: 'bbb-game-start',
          name: 'vmBigBangGame',
          component: vmBigBangGame,
        },
        {
          path: 'bbb-game-finish',
          name: 'vmBigBangGameFinish',
          component: vmBigBangGameFinish,
        },
        {
          path: 'success',
          name: 'vendingMachineSuccess',
          component: vendingMachineSuccess,
        },
      ],
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      if (savedPosition) {
        resolve(savedPosition);
      } else {
        resolve({ x: 0, y: 0 });
      }
    });
  },
});
