import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import maskStation from './modules/mask-station';
import QRcode from './modules/qr-code';
import lipstickbar from './modules/lipstickbar';
import productDetail from './modules/product-detail';
import recommendationMachine from './modules/recommendation-machine';
import checkinStation from './modules/checkin-station';
import pimplePop from './modules/pimple-pop';
import vm from './modules/vm';
import vmCola from './modules/vm-cola';
import dandelionStore from './modules/dandelion-store';
// import * as QRcode from './modules/qr-code';

const Config = require('~/config/default.env').default;

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    QRcode,
    maskStation,
    lipstickbar,
    productDetail,
    recommendationMachine,
    checkinStation,
    pimplePop,
    vm,
    vmCola,
    dandelionStore,
  },
  state: {
    loading_stores: false,
    store_fetched: false,
    all_store_loaded: false,
    stores: [],
    currentVideoDuration: 0,
    loading_vmcola: false,
    vmcola_fetched: false,
    all_vmcola_loaded: false,
    vmcola: [],
    loading_pagination: false,
    store: {
      skip: 0,
      limit: 150,
    },
    storeVn: {
      skip: 0,
      limit: 100,
    },
    vmcola_pagination: {
      skip: 0,
      limit: 10,
    },
    SOCO_WEB_URL: Config.SOCO_WEB_URL,
    SOCIOLLA_WEB_URL: Config.SOCIOLLA_WEB_URL,
    BJ_WEB_URL: Config.BJ_WEB_URL,
    beautyAtmData: null,
    loadingBeautyAtmData: true,
  },
  mutations: {
    setCurrentVideoDuration(state, duration) {
      state.currentVideoDuration = duration;
    },
  },
  actions: {
    async fetchCurrentVideoDuration({ commit }, videoUrl) {
      try {
        const videoElement = document.createElement('video');
        videoElement.src = videoUrl;

        await new Promise((resolve, reject) => {
          videoElement.addEventListener('loadedmetadata', () => {
            resolve();
          });

          videoElement.addEventListener('error', (error) => {
            reject(error);
          });
        });

        const duration = videoElement.duration;
        commit('setCurrentVideoDuration', duration);
      } catch (error) {
        console.error('Error fetching video duration:', error);
        // Optionally handle error
      }
    },
    async getVmStores({ state }, load_more = false) {
      if (!state.vmcola_fetched || (load_more && !state.all_vmcola_loaded && !state.loading_pagination)) {
        if (load_more) {
          state.loading_pagination = true;
        } else {
          state.loading_vmcola = true;
        }
        state.vmcola_fetched = true;
        await axios
          .get(`${Config.MS_CATALOG_API_URL}/vending-machines`, {
            params: {
              skip: state.vmcola_pagination.skip,
              limit: state.vmcola_pagination.limit,
              filter: {
                status: true,
                is_deleted: false,
              },
              sort: '-created_at',
              fields: 'id,name,alias,address,status,store_id',
            },
            headers: {
              'SOC-PLATFORM': 'vending-machine',
            },
          })
          .then((res) => {
            if (res.data.data && res.data.data.length) {
              if (!load_more) {
                state.vmcola = res.data.data;
              } else {
                state.vmcola = state.vmcola.concat(res.data.data);
              }
            } else {
              state.all_vmcola_loaded = true;
            }
            state.vmcola_pagination.skip = state.vmcola_pagination.skip + state.vmcola_pagination.limit;
            if (load_more) {
              setTimeout(() => {
                state.loading_pagination = false;
              }, 500);
            } else {
              state.loading_vmcola = false;
            }
          })
          .catch((err) => {
            if (load_more) {
              setTimeout(() => {
                state.loading_pagination = false;
              }, 500);
            } else {
              state.loading_vmcola = false;
            }
            state.all_vmcola_loaded = true;
            console.log(err);
          });
      }
    },
    async getPhysicalStores({ state, dispatch }, load_more = false) {
      if (!state.store_fetched || (load_more && !state.all_store_loaded && !state.loading_pagination)) {
        if (load_more) {
          state.loading_pagination = true;
        } else {
          state.loading_stores = true;
        }
        state.store_fetched = true;
        const headers = {
          'SOC-PLATFORM': 'vending-machine',
        };
        await axios
          .get(`${Config.MS_POS_API_URL}/stores`, {
            params: {
              skip: state.store.skip,
              limit: state.store.limit,
              sort: '-created_at',
              filter: {
                is_active: true,
                // created_from: 'sociolla',
                type: 'physical_store',
                without_source: true,
              },
            },
            headers: headers,
          })
          .then((res) => {
            if (res.data.data && res.data.data.length) {
              if (!load_more) {
                state.stores = res.data.data;
              } else {
                state.stores = state.stores.concat(res.data.data);
              }
              dispatch('saveStoreList', state.stores);
            } else {
              state.all_store_loaded = true;
            }
            state.store.skip = state.store.skip + state.store.limit;
            if (load_more) {
              setTimeout(() => {
                state.loading_pagination = false;
              }, 500);
            } else {
              state.loading_stores = false;
            }
          })
          .catch((err) => {
            if (load_more) {
              setTimeout(() => {
                state.loading_pagination = false;
              }, 500);
            } else {
              state.loading_stores = false;
            }
            state.all_store_loaded = true;
            console.log(err);
          });
      }
    },
    async getBeautyAtmData({ state }) {
      const date = new Date();
      return await axios
        .get(`${Config.MS_SOCIOLLA_PUBLIC_API_URL}/beauty-atm-rules`, {
          params: {
            limit: 1,
            filter: {
              is_active: true,
              end_date: { $gte: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` },
              start_date: { $lte: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` },
            },
            sort: '-created_at',
          },
          headers: {
            'SOC-PLATFORM': 'vending-machine',
          },
        })
        .then((res) => {
          if (res?.data?.data?.length) {
            if (res.data.data[0]?.products) {
              const sortProduct = res.data.data[0].products.sort((a, b) => (a.position || 0) - (b.position || 0));
              res.data.data[0].products = sortProduct;
            }
            state.beautyAtmData = res.data.data[0];
            state.loadingBeautyAtmData = false;
          } else {
            this._vm.$toasted.global.error('No Game is active');
            state.loadingBeautyAtmData = false;
            if (this._vm.$route.query.isvn) {
              this._vm.$router.push('/vending-machine-vn/home?isvn=true');
            } else {
              this._vm.$router.push('/vending-machine/home');
            }
          }
        })
        .catch((err) => {
          console.log(err);
          return [];
        });
    },
  },
  getters: {
    // Getter to access current video duration
    getCurrentVideoDuration: (state) => state.currentVideoDuration,
  },
});
