const Config = require('~/config/default.env').default;

export default {
  state: {
    background: '',
    checkinStationList: [],
    skip: 0,
    limit: 100,
    checkin_type: '',
    redirect_url: '',
    name: '',
    background_type: '',
    background_desktop: '',
    background_mobile: '',
    background_color: '',
    campaign_id: '',
    promotion_id: '',
    store_id: '',
    logo: '',
    title: '',
    tagline_before: '',
    tagline_after: '',
    checkin_id: '',
    loading: true,
  },
  mutations: {
    SET_LOADING(state, data) {
      state.loading = data;
    },
    setBackgroundCheckinStation(state, data) {
      state.background = data;
    },
    SET_CHECKIN_STATION_LIST(state, data) {
      state.checkinStationList = data;
    },
    SET_SKIP(state, data) {
      state.skip = data;
    },
    SET_CHECKIN_DETAIL(state, data) {
      state.checkin_type = data.checkin_type;
      state.redirect_url = data.redirect_url;
      state.name = data.name;
      state.background_type = data.background_type;
      state.background_desktop = data.background_desktop;
      state.background_mobile = data.background_mobile;
      state.background_color = data.background_color;
      state.logo = data.logo;
      state.title = data.title;
      state.tagline_before = data.tagline_before;
      state.tagline_after = data.tagline_after;
      state.campaign_id = data.campaign_id;
      state.promotion_id = data.promotion_id;
      state.store_id = data.store_id;
      state.is_event = data.is_event;
      state.checkin_id = data._id;
    },
  },
  actions: {
    async fetchCheckinDetailByStoreId({ state, commit }, id) {
      const headers = {
        'SOC-PLATFORM': 'checkin-station-event',
      };
      commit('SET_LOADING', true);
      return axios
        .get(`${Config.MS_SOCIOLLA_PUBLIC_API_URL}/checkin/store/${id}`, {
          headers,
        })
        .then((res) => {
          if (Object.keys(res.data.data || {}).length > 0) {
            commit('SET_CHECKIN_DETAIL', res.data.data);
          }
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    },
    async fetchCheckinDetailById({ state, commit }, id) {
      const headers = {
        'SOC-PLATFORM': 'checkin-station-event',
      };
      commit('SET_LOADING', true);
      return axios
        .get(`${Config.MS_SOCIOLLA_PUBLIC_API_URL}/checkin/${id}`, {
          headers,
        })
        .then((res) => {
          commit('SET_CHECKIN_DETAIL', res.data.data);
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    },
    async fetchCheckinStationList({ state, commit }, load_more = false) {
      const headers = {
        'SOC-PLATFORM': 'checkin-station-event',
      };
      const params = {
        skip: state.skip,
        limit: state.limit,
        sort: '-created_at',
        filter: { checkin_type: 'campaign' },
      };
      await axios
        .get(`${Config.MS_SOCIOLLA_PUBLIC_API_URL}/checkin`, {
          params,
          headers,
        })
        .then((res) => {
          commit('SET_CHECKIN_STATION_LIST', res.data.data);
          if (load_more) {
            commit('SET_SKIP', state.skip + state.limit);
          }
        });
    },
  },
  getters: {},
};
